import React, { useMemo } from 'react';
import LazyLoadingList from "@components/LazyLoadingList";
import ContractCard from "@components/Contracts/Card/ContractCard.component";

const ContractCardList = (props) => {
  const {
    loading = false,
    contracts = [],
    isLastPage = false,
    isEndReached,
    onContractClick
  } = props;

  const renderContractCard = (contract, i) => (
    <ContractCard
      contract={contract}
      key={i}
      onContractClick={onContractClick}
    />
  );

  const renderContent = useMemo(() =>
    <LazyLoadingList
      data={contracts || []}
      loading={loading}
      isLastPage={isLastPage}
      onEndReached={isEndReached}
      renderItem={renderContractCard}
    />
  , [loading, contracts]);

  return (
    <>
      {renderContent}
    </>
  );
};

export default React.memo(ContractCardList);
