import React from 'react';
import PropTypes from 'prop-types';

/** load components **/
import ApplyButton from '@components/Common/Button/ApplyButton';

/** import services **/
import {ContractFilterFormatter} from '@services/formatters/contracts/ContractsFilter.formatter';
import {t} from '@helpers/i18n';
import ContractFilterForm from "@components/Contracts/Filter/FilterForm/ContractFilterForm.component";

const ContractFilter = (props) => {
  const { filterValues, visible, contractDates, onDateChange, filterChanged, applyFilter } = props;

  const filterChange = (e) => {
    const updatedFilters = ContractFilterFormatter(e, filterValues);
    filterChanged(updatedFilters);
  };

  return (
    visible &&
        <div className={'list-filter'}>
          <ContractFilterForm
            contractDates={contractDates}
            filters={filterValues}
            onChange={filterChange}
            onDateChange={onDateChange}
          />
          <ApplyButton onClick={applyFilter} text={t('apply')} />
        </div>
  );
};

ContractFilter.propTypes = {
  filterValues: PropTypes.array,
  visible: PropTypes.bool,
  contractDates: PropTypes.object,
  onDateChange: PropTypes.func,
  filterChanged: PropTypes.func,
  applyFilter: PropTypes.func,
};

ContractFilter.defaultProps = {
  filterValues: [],
  visible: false,
  contractDates: {},
  onDateChange: () => {},
  filterChanged: () => {},
  applyFilter: () => {},
};

export default ContractFilter;
