import React, { useState, useRef } from 'react';
/** Load components **/
import UserProfileBlock from "@containers/Sidebar/UserBlock";
import LogoutButton from './Logout';
import SideMenu from './SideMenu';

import { useSelector } from "react-redux";
import classNames from "classnames";

/** Load styles **/
import './Sidebar.styles.scss';

const SideBar = () => {
  const [expandNav, setExpandNav] = useState(false);
  const sidebarRef = useRef(null);

  const { profile } = useSelector(state => state.profile);

  const name = profile?.name || '';
  const surname = profile?.surname || '';
  const customerNumber = profile?.customerNumber || '';
  const avatar = profile?.avatar || '';

  const expandNavBar = (expandFlag) => {
    setExpandNav(expandFlag);

    if (sidebarRef.current) {
      if (expandFlag) {
        sidebarRef.current.style.width = '180px';

        const contentWidth = sidebarRef.current.scrollWidth;

        sidebarRef.current.style.width = `${contentWidth+25}px`;
      } else {
        sidebarRef.current.style.width = '56px';
      }
    }
  };

  return (
    <div
      ref={sidebarRef}
      className={classNames('sidebar', { 'expand': expandNav })}
      onMouseEnter={() => expandNavBar(true)}
      onMouseLeave={() => expandNavBar(false)}
    >
      {/** User Profile Block **/}
      <UserProfileBlock
        customerNumber={customerNumber}
        avatar={avatar}
        name={name}
        surname={surname}
      />
      {/** Sidebar menu links **/}
      <SideMenu />
      {/** Logout Button **/}
      <LogoutButton />
    </div>
  );
};

export default SideBar;
