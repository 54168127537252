import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PageHeader from '@components/Layout/PageHeader';
import {useDispatch, useSelector} from 'react-redux';
import DataForm from '@components/Common/Form/DataForm';
import {currencyToLocaleFormatter} from '@services/utils/Number.formatters';
import {LicensePlate} from '@components/Car/LicensePlate';
import AnimatedLoader from '@components/Common/AnimatedLoader';
import SelectDriverCmp from '@components/Common/AddDriver';
import {useHistory, useParams} from 'react-router-dom';
import {ticketSingleGet} from '@store/tickets/actions';
import { t } from "@helpers/i18n";
import Grid from '@mui/material/Grid';
import ButtonGroup from '@components/Common/ButtonGroup';
import PersonIcon from 'mdi-react/AccountIcon';
import {Button} from '@components/Common/Button';
import {BUTTON_TYPES} from '@constants';
import './TicketDetails.style.scss';
import TrashOutlineIcon from 'mdi-react/TrashOutlineIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import CreditCardOutlineIcon from 'mdi-react/CreditCardOutlineIcon';
import ChatAlertIcon from 'mdi-react/ChatAlertIcon';
import {Api} from '@api';
import * as ticketActions from '../../../store/tickets/actions';
import UndoVariantIcon from 'mdi-react/UndoVariantIcon';
import Modal from '@components/Common/Modal';
import FinishTicketReasonModalComponent from '@components/Ticket/FinishTicketReasonModal';
import TicketManagementEmailComponent from '@components/Ticket/TicketManagementEmail';
import TicketPdfPreviewComponent from '@components/Ticket/TicketPdfPreview';
import TicketImagePreviewComponent from '@components/Ticket/TicketImagePreview';
import {dateFormatter} from "@services/formatters/util/DateTime.formatters";
import {IconButton} from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const full_YDM_dot = 'dd.MM.yyyy';

function TicketDetailContainer() {
  const {singleTicket, isSingleTicketLoading} = useSelector(state => state.tickets);
  const { auth: currentUser } = useSelector((state) => state.auth);
  const [ticketMediaFiles, setTicketMediaFiles] = useState([]);
  const [fileForPreview, setFileForPreview] = useState(0);
  const [finishModalVisible, setFinishModalVisible] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [emailForm, setEmailForm] = useState({type: '', visible: false});
  const [errors, setErrors] = useState({});
  const [selectedDriver, setSelectedDriver] = useState();
  const { id: ticketId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();



  /** Load Ticket Details **/
  useEffect(() => {
    if (ticketId) {
      dispatch(ticketSingleGet({id: ticketId}));
    }
  }, [ticketId]);

  /**
   *  Checking if the media array has a pdf file.
   *  If it does, it sets the ticketPdf state to the pdf file.
   */
  useEffect(() => {
    if (singleTicket?.media?.length) {
      const media = [];
      singleTicket.media.forEach(file => {
        media.push({type: file.includes('pdf') ? 'pdf' : 'jpg', content: file});
      });
      setTicketMediaFiles(media);
    }
  }, [singleTicket]);

  /**
   *  A Handler when a driver is selected.
   *  It sets the selected driver and removes the error message.
   */
  const onAccuse = useCallback((driver) => {
    setSelectedDriver(driver);
    if(driver) {
      setErrors({...errors, noDriverAssign: false});
    }
  }, []);

  /**
   * Renders page header
   * @type {JSX.Element}
   */
  const renderHeader = useMemo(() => (
    <PageHeader text={t("back")} profile={true} backLink={'/tickets'}>
      <div className="header-ticket-row">
        <div className={'ticket-heading-title'}>
          {t('tickets')}
        </div>
      </div>
    </PageHeader>
  ), []);

  /**
   * Ticket data for preview
   */
  const ticketDetailsData = useMemo(() => ([
    {
      name: t("license-plate"),
      value:  <LicensePlate
        country="D"
        license={singleTicket?.['licence-plate']}
      />,
    },
    {
      name: t("file_number"),
      value: singleTicket?.['government-reference'],
    },
    {
      name: t("ticket_date"),
      value: dateFormatter(singleTicket?.['event-date'], full_YDM_dot),
    },
    {
      name: t("vehicle-driver"),
      value: singleTicket?.['assignee-detail']?.name || singleTicket?.['assignee-detail']?.surname ?
        `${ singleTicket?.['assignee-detail']?.name } ${ singleTicket['assignee-detail']?.surname }` : '-'
    },
    {
      name: t("amount"),
      value: `${ currencyToLocaleFormatter(singleTicket?.amount/100) }`,
    },
    {
      name: t("authority_"),
      value: singleTicket?.government,
    },
    {
      name: t("email_word_full"),
      value: singleTicket["government-email"],
    },
    {
      name: t("government-iban"),
      value: singleTicket["government-iban"],
    },
    {
      name: t("status_finished"),
      value: singleTicket?.reason,
    },
  ]), [singleTicket]);


  const deleteTicket = useCallback(() => {
    setConfirmModalVisible(false);
    dispatch(ticketActions.ticketDelete({id: singleTicket.id, history}));
  }, [dispatch, singleTicket]);

  const assignTicket = useCallback((emailData) => {
    if(selectedDriver) {
      dispatch(ticketActions.ticketAssign({id: singleTicket.id, userId: emailData.assignee, emailData}));
      setEmailForm({type: '', visible: false});
    }
  }, [dispatch, singleTicket, selectedDriver]);

  const objectTicket = useCallback((emailData) => {
    dispatch(ticketActions.ticketObjected({id: singleTicket.id, emailData}));
    setEmailForm({type: '', visible: false});
  }, [dispatch, singleTicket]);

  const finishTicket = useCallback((reason) => {
    dispatch(ticketActions.ticketComplete({id: singleTicket.id, reason}));
  }, [dispatch, singleTicket]);

  const resetTicket = useCallback(() => {
    dispatch(ticketActions.ticketReset({id: singleTicket.id}));
  }, [dispatch, singleTicket]);

  const openLink = useCallback((link) => {
    window.open(link, '_blank');
  }, []);

  const getPaymentLink = useCallback((data) => data?.['payment-link'] || data?.['payrexx-link'], []);

  const payTicket = useCallback(() => {
    const linkToOpen = getPaymentLink(singleTicket);

    if (linkToOpen) {
      openLink(linkToOpen);
    } else {
      Api.tickets.pay({id: singleTicket.id})
        .then(res => {
          const responseLink = getPaymentLink(res.data);
          if (responseLink) {
            openLink(responseLink);
          }
        });
    }
  }, [singleTicket, getPaymentLink, openLink]);

  const onAssignButtonClick = () => {
    if(!selectedDriver) {
      setErrors({...errors, noDriverAssign: true});
      return;
    }
    setEmailForm({visible: true, type: 'assign'});
  };

  /**
   * List of action buttons that could be rendered on the page
   */
  const allButtons = {
    accuse: {
      label: t('accuse'),
      icon: <PersonIcon/>,
      action: onAssignButtonClick
    },
    reset: {
      label:  t('reset_ticket'),
      icon: <UndoVariantIcon/>,
      action: resetTicket
    },
    object: {
      label:  t('object'),
      icon: <ChatAlertIcon/>,
      action: () => setEmailForm({visible: true, type: 'object'})
    },
    finish: {
      label:  t('finish'),
      icon: <CheckIcon/>,
      action: () => setFinishModalVisible(true)
    },
    pay: {
      label:  t('pay'),
      icon: <CreditCardOutlineIcon/>,
      action: payTicket
    }
  };

  /**
   * Returns array of action buttons depending on ticket state
   */
  const availableButtons = useMemo(() => {
    if (['finished', 'manual'].includes(singleTicket.state)) {
      return [allButtons.reset];
    }

    const buttons = [];

    if(!singleTicket.assignee && singleTicket.state !== 'objected') {
      buttons.push(allButtons.accuse);
    }

    if(singleTicket.assignee) {
      buttons.push(allButtons.reset);
    }

    if(singleTicket.state ==='open' || singleTicket.state==='assigned') {
      buttons.push(allButtons.object);
    }

    if(singleTicket.state === 'objected' && !singleTicket.assignee) {
      buttons.push(allButtons.reset);
    }

    buttons.push(allButtons.finish);

    buttons.push(allButtons.pay);

    return buttons;
  }, [singleTicket, selectedDriver]);



  /**
   * Renders ticket data and action buttons
   * @type {JSX.Element}
   */
  const renderTicketDetails = useMemo(() => (
    <Grid item xs={5} className={'ticket-details-overview'}>
      <div>
        <DataForm data={ticketDetailsData} />

        {!singleTicket.assignee && <SelectDriverCmp onDriverSelected={onAccuse}/>}
        {errors.noDriverAssign && <p className={'error-message'}>{t('error-select-driver')}</p>}
      </div>

      <div className={'action-buttons'}>
        <ButtonGroup buttonGroup={availableButtons} showOptions={availableButtons.length > 1} />
        <Button
          className={'delete-button'} text={t('delete-ticket')}
          onClick={() => setConfirmModalVisible(true)}
          type={BUTTON_TYPES.DANGER} isFlat={true} icon={<TrashOutlineIcon />} />
      </div>

      <FinishTicketReasonModalComponent
        onClose={() => setFinishModalVisible(false)}
        visible={finishModalVisible}
        onSubmit={finishTicket} />

      <Modal
        className={'confirm-delete-modal'}
        onClose={() => setConfirmModalVisible(false)}
        isVisible={confirmModalVisible}>
        <div className={'confirm-delete-modal-content'}>
          <p className={'confirm-modal-title'}>{t('sure_delete_ticket')}</p>
          <Button
            icon={<CheckIcon />}
            name="success"
            value="btn-success"
            type={BUTTON_TYPES.SUCCESS}
            text={t("confirm")}
            isSubmit
            isSmall
            onClick={deleteTicket}
          />
        </div>
      </Modal>
    </Grid>
  ), [singleTicket, selectedDriver, errors, finishModalVisible, confirmModalVisible]);


  /**
   * Renders email form for objection nad assigning ticket
   * @type {JSX.Element}
   */
  const renderEmailForm = useMemo(() => (
    <Grid item xs={5} className={'details-content h-100'} style={{paddingTop: 0}}>
      <TicketManagementEmailComponent
        onClose={() => setEmailForm({type: '', visible: false})}
        assignee={emailForm.type === 'assign' ? selectedDriver?.userId : null}
        currentUser={currentUser.info}
        targetEmail={singleTicket['government-email'] || ''}
        licencePlate={singleTicket['licence-plate'] || ''}
        ticketNumber={singleTicket?.['government-reference']}
        driver={selectedDriver}
        onSubmit={emailForm.type === 'assign' ? assignTicket : objectTicket} />
    </Grid>
  ), [singleTicket, currentUser, emailForm, selectedDriver]);


  const previousFileButton = useMemo(() => (
    <IconButton
      color="primary" aria-label="upload picture" component="span"
      disabled={fileForPreview === 0}
      onClick={() => setFileForPreview(fileForPreview - 1)}>
      <ArrowCircleLeftIcon fontSize={'large'}/>
    </IconButton>
  ), [fileForPreview]);

  const nextFileButton = useMemo(() => (
    <IconButton
      color="primary" aria-label="upload picture" component="span"
      disabled={fileForPreview === ticketMediaFiles?.length - 1}
      onClick={() => setFileForPreview(fileForPreview + 1)}>
      <ArrowCircleRightIcon fontSize={'large'} />
    </IconButton>
  ), [fileForPreview, ticketMediaFiles]);

  /**
   * Renders page's main content
   * @type {JSX.Element}
   */
  const renderPageContent = useMemo(() => (
    <div className={'details-container'}>
      {renderHeader}
      {!!singleTicket && !!Object.keys(singleTicket).length &&
        <Grid container style={{ flexGrow: 1 }}>
          <>
            {emailForm.visible ? renderEmailForm : renderTicketDetails}
            {ticketMediaFiles.length > 0 &&
              <Grid item xs={7}>
                {ticketMediaFiles[fileForPreview]?.type === 'pdf' ?
                <TicketPdfPreviewComponent pdfUrl={ticketMediaFiles[fileForPreview]?.content}/>
                :
                singleTicket?.media &&
                <TicketImagePreviewComponent ticketImages={[ticketMediaFiles[fileForPreview]?.content]}/>
                }
                <div className={'file-navigation'}>
                  {previousFileButton}
                  <p>File {fileForPreview + 1} / {ticketMediaFiles?.length}</p>
                  {nextFileButton}
                </div>

              </Grid>}
          </>
        </Grid>
      }
    </div>
  ), [
    singleTicket,
    renderHeader,
    renderTicketDetails,
    onAccuse,
    errors,
    emailForm,
    finishModalVisible,
    ticketMediaFiles,
    fileForPreview
  ]);

  return (
    <div className={'ticket-details'}>
      {isSingleTicketLoading ?
        <AnimatedLoader /> :
        renderPageContent
      }
    </div>
  );
}

export default TicketDetailContainer;
