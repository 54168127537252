import {combineReducers} from "redux";
import app from "./app/reducer";
import auth from "./auth/reducer";
import map from "./map/reducer";
import profile from "./profile/reducer";
import tickets from "./tickets/reducer";
import trips from "./trips/reducer";
import employees from "./employees/reducer";
import damages from "./damages/reducer";
import vehicles from "./vehicles/reducer";
import carchecks from "./carcheck/reducer";
import appointments from "./appointments/reducer";
import invoices from "./invoices/reducer";
import settings from "./settings/reducer";
import reporting from './reporting/reducer';
import garages from './garages/reducer';
import tracking from './tracking/reducer';
import dailyRent from './dailyRent/reducer';
import contracts from './contracts/reducer';

const reducers = combineReducers({
  app,
  auth,
  map,
  profile,
  tickets,
  trips,
  employees,
  damages,
  vehicles,
  carchecks,
  appointments,
  invoices,
  settings,
  reporting,
  garages,
  tracking,
  dailyRent,
  contracts
});

export {reducers};
