/** Contract filter states **/
export const CONTRACT_FILTER_STATES = ['active', 'inactive'];

const ContractFilterFormatter = (e, filters) => {
  const { id, checked } = e.currentTarget;
  let updateFilters = [...filters];
  const filterId = parseInt(id, 10);
  const filterIndex = filters.findIndex((filter) => filter.id === filterId);

  if (filterIndex === 0) {
    updateFilters = updateFilters.map((filter) => ({ ...filter, value: checked }));
  } else {
    updateFilters[filterIndex].value = checked;
    // check if "Show all" is selected and change the state of other buttons in filter to true/false
    updateFilters[0].value = updateFilters.slice(1, 4).every((filter) => filter.value);
  }

  return updateFilters;
};

/** Helper for Contracts List query
 * formats filters, search into query
 * @param {array} filters filter options array
 * **/
const ContractsQueryFormatter = (filters) => {
  if (filters[0].value) return '';

  return filters
    .slice(1, 3)
    .reduce(
      (query, filter, index) => (filter.value ? `${query}&filterState[]=${CONTRACT_FILTER_STATES[index]}` : query),
      ''
    );
};

export { ContractFilterFormatter, ContractsQueryFormatter };
