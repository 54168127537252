import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import * as contractActions from '@store/contracts/actions';
import ContractListWrapper from '@containers/Contracts/ContractList/ContractListWrapper';

const ContractListHolder = () => {
  const dispatch = useDispatch();
  const { contracts = [], loading = false, lastPage = false } = useSelector((state) => state.contracts);
  // console.log(contracts);
  const { auth } = useSelector((state) => state.auth);

  /**
   * Fetch Contracts
   * @param {number} page - current page
   * @param {string} filter - contract filters
   * @param {string} search - value from search field
   * @param {object} dateFrom - starting Date
   * @param {object} dateTo - end Date
   **/
  const filterContracts = ({ page, filter, search, dateFrom, dateTo }) => {
    dispatch(
      contractActions.contractGet({
        userId: auth.user.id,
        filters: filter,
        search: search ? `&search=${search}` : '',
        page: page,
        dateFrom: dateFrom ? `&filterDateFrom=${format(dateFrom, 'yyyy-MM-dd')}` : '',
        dateTo: dateTo ? `&filterDateTo=${format(dateTo, 'yyyy-MM-dd')}` : ''
      })
    );
  };

  /**
   * Contract List End reached
   * @param {number} page - current page
   * @param {string} filter - contract filters
   * @param {string} search - value from search field
   * @param {object} dateFrom - starting Date
   * @param {object} dateTo - end Date
   **/
  const isEndReached = ({ page, filter, search, dateFrom, dateTo }) => {
    dispatch(
      contractActions.contractLoadMore({
        userId: auth.user.id,
        filters: filter,
        search: search ? `&search=${search}` : '',
        page: page,
        dateFrom: dateFrom ? `&filterDateFrom=${format(dateFrom, 'yyyy-MM-dd')}` : '',
        dateTo: dateTo ? `&filterDateTo=${format(dateTo, 'yyyy-MM-dd')}` : ''
      })
    );
  };

  const memorizedContracts = useMemo(() => {
    return (
      <ContractListWrapper
        contracts={contracts}
        loading={loading}
        lastPage={lastPage}
        applyContractFilter={filterContracts}
        endReached={isEndReached}
      />
    );
  }, [JSON.stringify(contracts), loading, lastPage]);

  return <>{memorizedContracts}</>;
};

export default React.memo(ContractListHolder);
