import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Link} from 'react-router-dom';
import {Api} from "@api";
import {t, date} from "@helpers/i18n";

/** load components **/
import AnimatedLoader from '@components/Common/AnimatedLoader';
import {LicensePlate} from "@components/Car/LicensePlate";
import {Spinner} from "@components/Spinner";
import AlertCmp from "@components/Common/Alert";
import NoPermission from "@components/Common/NoPermission";

/** load Material Design Icons **/
import CloseIcon from "mdi-react/CloseIcon";
import FileExport from "mdi-react/FileExportOutlineIcon";

import "./index.scss";

const DamageDetails = () => {

  /**
   * Redux selectors
   */
  const {damage = {}, damageLoading} = useSelector(state => state.damages);
  const language = useSelector(state => state.profile.profile?.language || 'de-DE');
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [loader, setLoader] = useState(false);

  /** Export damage as pdf, png or jpg if available **/
  const exportDamage = async () => {
    try {
      const {data} = await Api.damages.exportDamage(damage.id);

      if (data.length) {

        const fileTypes = {
          'J': 'pdf',
          '/': 'jpg',
          'i': 'png',
        };

        // // eslint-disable-next-line prefer-destructuring
        const [exportedDamage] = data;

        const fileType = fileTypes[exportedDamage.blob.charAt(0)];
        const linkSource = `data:application/${fileType};base64,${exportedDamage.blob}`;
        const downloadLink = document.createElement("a");
        const fileName = `damage_${damage.id}.${fileType}`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        setLoader(false);
      }
      else {
        setConfirmPopup(true);
        setLoader(false);
      }
    }
    catch (e) {
      console.log(e);
    }
  };

  const exportReport = async () => {
    setLoader(true);
    await exportDamage();
  };

  const mileageToLocale = (mileage, language = 'de-DE') => {
    if (mileage) {
      return `${Number(mileage).toLocaleString(language)} km`;
    }
    return '-';
  };

  const currencyToLocale = (x) => {
    return Number(x).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
  };

  const closePopup = () => {
    setConfirmPopup(false);
  };

  /**
   *  Render Animated Loader
   **/
  const renderAnimatedLoader = () => {
    return (<AnimatedLoader />);
  };

  /**
   * It renders the details of a damage report
   */
  const renderDetails = () =>
    <div className="flex h-100 p-b-2 flex-col justify-between">
      <>
        {loader && <Spinner/>}

        <article className={'damage-classification'}>
          <div className="details-column">
            <div className="details-license">
              <div className="damage-type damage-type-size">
                {damage?.damageType}
              </div>
              <LicensePlate
                country="D"
                license={damage?.vehicleId}
              />
            </div>
            <div className="details-line">
              <span className="details-row">{t('damage-id')}</span>{damage?.damageNumber}
            </div>
            <div className="details-driver">
              <div>
                <div className="details-line">
                  <span className="details-row">{t('damage_classification')}</span>{(damage?.damagePositions && damage?.damagePositions[0]?.description) ? `${damage?.damagePositions[0]?.description}` : '-'}
                </div>
                <div className="details-line">
                  <span className="details-row">{t('driver')}</span>{(damage?.driverName && !!damage?.driverSurname) ? `${damage?.driverName}  ${damage?.driverSurname}` : '-'}
                </div>
                <div className="details-line">
                  <span className="details-row">{t('mileage')}</span>{damage?.mileage !== "" ? `${mileageToLocale(damage?.mileage, language)}` : '-'}
                </div>
                <div className="details-line">
                  <span className="details-row">{t('date_dot')}</span>{damage?.date ? `${date(damage?.date)}` : '-'}
                </div>
                <div className="details-line">
                  <span className="details-row">{t('state')}</span>{damage?.appState ? `${t(damage?.appState)}` : '-'}
                </div>
              </div>
            </div>
          </div>
          <div className="details-column">
            <div className="details-line">
              <span className="details-row" style={{marginTop: "37px"}}>{t('damage-report')}:</span>{damage?.desc ? `${damage?.desc}` : '-'}
            </div>
            <div className="details-line">
              <span className="details-row">{t('damage-costs')}</span>{damage?.damageCosts ? `${currencyToLocale(damage?.damageCosts)}` : '-'}
            </div>
          </div>
        </article>
      </>
      <div className={'exports'}>
        <div className="damage-export" onClick={exportReport}><FileExport/><span className="title_btn">{t('expert_report')}</span></div>
        <div className="damage-export" style={{background: "#E84D0E", marginTop: "10px"}}>
          <Link to={`/damages/${damage.id}/reclamation`}>
            <CloseIcon/><span className="title_btn">{t('reclamation')}</span>
          </Link>
        </div>
      </div>
      <AlertCmp
        visible={confirmPopup}
        title={t('report_not_available')}
        onClick={closePopup}/>
    </div>;

  return (
    <>
      {damageLoading && renderAnimatedLoader()}
      {damage?.id ? renderDetails() : <NoPermission />}
    </>
  );
};

export default DamageDetails;
