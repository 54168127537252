import { call, put, takeLatest } from 'redux-saga/effects';
import { errorHandler } from '@helpers/saga';
import * as contractConstants from './constants';
import * as contractActions from './actions';
import { Api } from '@api';

function* getContracts(action) {
  try {
    const { userId, page, filters, search, dateFrom, dateTo } = action.payload;
    const { data } = yield call(Api.contracts.getContracts, { userId, search, page, filters, dateFrom, dateTo });

    yield put(contractActions.contractGetSuccess(data));
  } catch (err) {
    yield call(errorHandler, err);
    yield put(contractActions.contractGetFail(err));
  }
}

function* loadMoreContracts(action) {
  try {
    const { userId, page, filters, search, dateFrom, dateTo } = action.payload;
    const { data } = yield call(Api.contracts.getContracts, { userId, search, page, filters, dateFrom, dateTo });
    yield put(contractActions.contractLoadMoreSuccess(data));
  } catch (err) {
    yield call(errorHandler, err);
    yield put(contractActions.contractLoadMoreFail(err));
  }
}

export function* getContractDetails(action) {
  try {
    const { userId, contractId } = action.payload;
    const { data } = yield call(Api.contracts.getContractDetails, { userId, contractId });
    yield put(contractActions.contractDetailsGetSuccess(data));
  } catch (err) {
    yield call(errorHandler, err);
    yield put(contractActions.contractDetailsGetFail(err));
  }
}

export function* contractsSagas() {
  yield takeLatest(contractConstants.CONTRACT_GET_REQUEST, getContracts);
  yield takeLatest(contractConstants.CONTRACT_LOAD_MORE_REQUEST, loadMoreContracts);
  yield takeLatest(contractConstants.CONTRACT_GET_DETAILS_REQUEST, getContractDetails);
}
