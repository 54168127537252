import React from 'react';
import PropTypes from 'prop-types';

/** Load components **/
import Toggler from '@components/Common/Toggler';
import { t } from '@helpers/i18n';
import FilterDatePicker from '@components/Common/FilterDatePicker';

const ContractFilterForm = (props) => {
  const { filters, contractDates, onChange, onDateChange } = props;

  return (
    <div className={'filter-rows'}>
      <div className="toggle">
        {filters.map((filterItem, index) => (
          <label key={index}>
            <p>{t(filterItem.filter)}</p>
            <Toggler id={index.toString()} checked={filterItem.value} onChange={onChange} />
          </label>
        ))}
        {filters[3].value && (
          <div className="date-picker">
            <FilterDatePicker
              dateFrom={contractDates.dateFrom}
              dateTo={contractDates.dateTo}
              onDateChange={onDateChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

ContractFilterForm.propTypes = {
  filters: PropTypes.array,
  contractDates: PropTypes.object,
  onChange: PropTypes.func,
  onDateChange: PropTypes.func
};

ContractFilterForm.defaultProps = {
  filters: [],
  contractDates: {},
  onChange: () => {},
  onDateChange: () => {}
};

export default ContractFilterForm;
