import { requestMs } from "@helpers/api";

export const contracts = {
  getContracts: ({ userId, search, filters, page, dateFrom, dateTo }) =>
    requestMs.get(
      `api3/user/${userId}/contracts?page=${page}${filters ?? ''}${search ?? ''}${dateFrom ?? ''}${dateTo ?? ''}`,
      {}
    ),
  getContractDetails: ({ userId, contractId }) => {
    return requestMs.get(`/api3/user/${userId}/contract/${contractId}`, {});
  }
};
